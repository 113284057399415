// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acknowledgements-js": () => import("./../../../src/pages/acknowledgements.js" /* webpackChunkName: "component---src-pages-acknowledgements-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-funds-raised-js": () => import("./../../../src/pages/funds-raised.js" /* webpackChunkName: "component---src-pages-funds-raised-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meet-the-artist-js": () => import("./../../../src/pages/meet-the-artist.js" /* webpackChunkName: "component---src-pages-meet-the-artist-js" */),
  "component---src-pages-organizations-js": () => import("./../../../src/pages/organizations.js" /* webpackChunkName: "component---src-pages-organizations-js" */),
  "component---src-pages-performances-js": () => import("./../../../src/pages/performances.js" /* webpackChunkName: "component---src-pages-performances-js" */),
  "component---src-pages-publicity-materials-js": () => import("./../../../src/pages/publicity-materials.js" /* webpackChunkName: "component---src-pages-publicity-materials-js" */),
  "component---src-pages-recordings-js": () => import("./../../../src/pages/recordings.js" /* webpackChunkName: "component---src-pages-recordings-js" */),
  "component---src-pages-sponsor-js": () => import("./../../../src/pages/sponsor.js" /* webpackChunkName: "component---src-pages-sponsor-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-what-we-can-do-js": () => import("./../../../src/pages/what-we-can-do.js" /* webpackChunkName: "component---src-pages-what-we-can-do-js" */),
  "component---src-templates-article-index-js": () => import("./../../../src/templates/article-index.js" /* webpackChunkName: "component---src-templates-article-index-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

